import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faCoffee,
  faSchool,
  faChartLine,
  faBook,
  faChartBar
} from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Help your students thrive independently",
    paragraph:
      "We've developed a suite of games and an analytics platform to help assess and develop these skills through gamification in a targeted fashion."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faStore} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Store Clerk Training</h4>
                  <p className="m-0 text-sm">
                    Watch as your students learn how to check out customers,
                    collect shopping carts, and bag items for customers.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faCoffee} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Morning Readiness</h4>
                  <p className="m-0 text-sm">
                    Our platform covers preparing for a day at school, a job
                    interview, and a day with friends.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faSchool} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Academic Learning</h4>
                  <p className="m-0 text-sm">
                    In addition to transitional games, we have games that will
                    enhance your student's academic learning.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faChartBar} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Intuitive Progress Reporting</h4>
                  <p className="m-0 text-sm">
                    Our platform allows for you to track student progress over
                    time, for easy reporting on IEPs.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faBook} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Adaptive Learning</h4>
                  <p className="m-0 text-sm">
                    Different levels and tutorials available for students and
                    teachers to slowly advance through different topics.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FontAwesomeIcon icon={faChartLine} size="3x" />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Quantitative Analysis</h4>
                  <p className="m-0 text-sm">
                    Records of gameplay and scores for each game, with simple to
                    track visualizations of progress.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
