import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, hideNav, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      {hideNav ? (
        <h1 className="m-0">
          <Link to="/">
            <Image
              src={require("./../../../assets/images/Icon.png")}
              alt="Open"
              width={32}
              height={32}
            />
          </Link>
        </h1>
      ) : (
        <h1 className="p-8">
          <Link to="/">
            <Image
              src={require("./../../../assets/images/LifeSkills.png")}
              alt="Open"
              width={200}
              height={200}
            />
          </Link>
        </h1>
      )}
    </div>
  );
};

export default Logo;
